import { Component, OnInit, Inject, Optional, ViewChild, AfterViewChecked } from '@angular/core';
import {  MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import {  Subject } from 'rxjs';
import { PurchaseRangeCategoryService } from '../../../core/service/purchase-range-category.service';
import { Store } from '@ngrx/store';
import {
  AppState,
  GetPurchaseRangeCategorySuccess,
  GetPurchaseTargetingCategorySuccess
} from '../../../store';
import { PurchaseTargetingCategoryService } from '../../../core/service/purchase-targeting-category.service';
import {tap} from 'rxjs/operators';
import {ItemOfferRangeCategoryFilter, ItemOfferRangeCategoryService} from '../../../core/service/item-offer-range-category.service';

@Component({
  selector: 'app-list-item-dialog',
  templateUrl: './list-item-dialog.component.html',
  styleUrls: ['./list-item-dialog.component.scss']
})
export class ListItemDialogComponent implements OnInit, AfterViewChecked {

  public modalTitle = '';
  public items$: any;
  public localItems: any;
  public selectAll = false;
  public loading = true;
  public loadingItems = true;

  public totalElems = 0;
  public limit = 20;
  public page = 0;
  public initalTotal = 0;
  private subject: Subject<string> = new Subject();
  public filter: string;
  public isEquativ = false;
  private shouldPrecheckItems = false;

  @ViewChild('list', {static: false}) list;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ListItemDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private purchaseRangeCategoryService: PurchaseRangeCategoryService,
    private purchaseTargetingCategoryService: PurchaseTargetingCategoryService,
    private itemOfferRangeCategoryService: ItemOfferRangeCategoryService,
    private storeApp: Store<AppState>,
  ) { }

  ngOnInit() {
    if ((this.data && this.data.alreadyAddedTargetings) || (this.data && this.data.alreadyAddedRanges)) {
      this.shouldPrecheckItems = true;
    }
    this.getItems();
    this.subject.debounceTime(400)
      .filter(items => this.initalTotal >= this.limit)
      .pipe(tap(() => this.loadingItems = true))
      .subscribe(searchTextValue => {
        if (this.paginator) {
          this.paginator.pageIndex = 0;
        }
        this.page = 0;
        this.getItems(searchTextValue);
      });

  }
  ngAfterViewChecked() {
    if (this.shouldPrecheckItems && this.list && this.list.options) {
      setTimeout(() => {
        if (this.data.alreadyAddedTargetings) {
          this.list.options._results.forEach(option => {
            if (option._value.id) {
              option.selected = this.data.alreadyAddedTargetings
                .find(alreadyAddedTargeting => alreadyAddedTargeting.id === option._value.id);
            }
          });
        }
        if (this.data.alreadyAddedRanges) {
          this.list.options._results.forEach(option => {
            if (option._value.id) {
              option.selected = this.data.alreadyAddedRanges
                .find(alreadyAddedRange =>
                  alreadyAddedRange.id === option._value.id);
            }
          });
        }

        this.shouldPrecheckItems = false;
      });
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  private getItems(value = '') {
    switch (this.data.type) {
      case 'purchaseRangeCategory':
        this.getPurchaseRangeCategories(value);
        break;
      case 'purchaseTargetingCategory':
        this.getPurchaseTargetingCategories(value);
        break;
      case 'itemOfferRangeCategory':
        this.getItemOfferRangeCategories(value);
        break;

      default:

    }
  }

  public addItems() {
    this.dialogRef.close(this.list.selectedOptions.selected.map(data => data.value));
  }

  public selectAllToggle() {
    this.selectAll = !this.selectAll;
    this.list.options._results.forEach(data => {
      data.selected = this.selectAll;
    });
  }

  /**
   * Only search if more than $limit item
   * @param value
   */
  public search(value: string, ): void {
     this.subject.next(value);
  }

  public changePage(event: any): void {
    this.page = event.pageIndex;
    this.getItems(this.filter);
  }

  private getPurchaseRangeCategories(value = '') {
    this.loadingItems = true;
    this.items$ = this.storeApp.select('purchaseRangeCategory');

    const filter: any = {
      categoryType: this.data.filters.typeCategory.replace('Equativ', ''),
      codeFormat: this.data.filters.codeFormat,
      page: this.page + 1,
      name: value};

    this.purchaseRangeCategoryService
      .getList(filter)
      .subscribe(data => {
        this.storeApp.dispatch(new GetPurchaseRangeCategorySuccess(data['items']));
        this.loading = false;
        this.loadingItems = false;
        this.totalElems = data['totalItems'] || data['items'].length;

        if (this.initalTotal === 0) {
          this.initalTotal = data['totalItems'] || data['items'].length;
        }
      });
  }

  private getPurchaseTargetingCategories(value = '') {
    // get geo here
    this.loadingItems = true;
    this.items$ = this.storeApp.select('purchaseTargetingCategory');

    const filterToPass: any = {
      typeCategory: this.data.filters.typeCategory.replace('Equativ', ''),
      typeTargeting: this.data.filters.typeTargeting,
      typeDispo: this.data.filters.typeDispo,
      page: this.page + 1,
      name: value,
    };
    if (this.data.extraFilter) {
      filterToPass.extraFilter = this.data.extraFilter;
    }
    if (this.data.filters.format) {
      filterToPass.format = this.data.filters.format;
    }
    if (this.data.filters.isYoutubeFormat) {
      filterToPass.isYoutubeFormat = this.data.filters.isYoutubeFormat;
    }
    // add equativ params
    // if (this.data.filters.isEquativItem) {
    if (this.data.filters.typeCategory.includes('Equativ')) {
      this.isEquativ = true;
      filterToPass.isEquativItem = this.data.filters.isEquativItem;
    } 

    this.purchaseTargetingCategoryService
      .getList(filterToPass)
      .subscribe(data => {
        this.storeApp.dispatch(new GetPurchaseTargetingCategorySuccess(data));
        this.loading = false;
        this.loadingItems = false;

        const itemsLength = data['items'] ? data['items'].length : 0;
        this.totalElems = data['totalItems'] || itemsLength;

        if (this.initalTotal === 0) {
          this.initalTotal = data['totalItems'] || itemsLength;
        }
      });
  }

  private getItemOfferRangeCategories(value) {
    this.loadingItems = true;
    const filter: ItemOfferRangeCategoryFilter = {
      subTypeCategory: this.data.filters.subTypeCategory,
      typeFormat: this.data.filters.typeFormat,
      name: value};

    this.itemOfferRangeCategoryService
      .getList(filter)
      .subscribe(data => {
        this.loading = false;
        this.loadingItems = false;
        if (data && data['items'] && data['total']) {
          this.localItems = data['items'];
        }
      });
  }
}
