import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { UpdateItemOffer, AppState, UpdatePurchaseItemRef, ResetItemOffer } from '../../../store';
import { Store } from '@ngrx/store';
import { isObjectValidator } from '../../../core/validator/is-object.validator';
import { AppConstants } from '../../../app.constants';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ISubscription } from 'rxjs-compat/Subscription';
import { ItemOffer } from '../../../core/model/item-offer.model';
import { OfferType } from '../../../core/model/offer-type.model';
import { ItemOfferTemplate, OutputItemOfferTemplate } from '../../../core/model/item-offer-template.model';
import { ResetItemOfferTemplate, UpdateItemOfferTemplate } from '../../../store/item-offer-template';
import { Offer } from '../../../core/model/offer.model';
import { JsonStep } from '../../../core/model/step.model';
import { ItemOfferService } from '../../../core/service/item-offer.service';
import { OfferService } from '../../../core/service/offer.service';
import { OfferTypeService } from '../../../core/service/offer-type.service';
import { ItemOfferInfoPopupComponent } from './item-offer-info-popup/item-offer-info-popup.component';

@Component({
  selector: 'app-item-offer',
  templateUrl: './item-offer.component.html',
  styleUrls: ['./item-offer.component.scss']
})

export class ItemOfferComponent implements OnInit, OnDestroy {
  public offers: Offer[];
  public filteredOffers$: Observable<Offer[]>;
  public offerTypes: OfferType[];
  public filteredOfferTypes$: Observable<OfferType[]>;
  public isOfferTypePreselected =  false;
  public isOfferTypeSelected = false;
  public itemOffers: ItemOffer[];
  public templates: ItemOfferTemplate[];
  public filteredItemOffers$: Observable<ItemOffer[]>;
  public form: FormGroup;
  public offersLoading = false;
  public offerTypesLoading = false;
  public offerPlaceholderText = 'Choisir une offre niveau 1';
  public noOfferMsg = 'Pas d\'offre niveau 1';
  public currentPurchaseItemsSubscription: ISubscription;
  public itemOfferSubscription: ISubscription;
  public isValidItemOffer = false;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeVideo;
  @Input() purchaseStep: JsonStep = null;
  private blockOfferStep = 3;

  constructor(
    private offerService: OfferService,
    private offerTypeService: OfferTypeService,
    private itemOfferService: ItemOfferService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.offersLoading = true;
    this.offerTypesLoading = true;

    this.initForm();
    this.getOffers();
    this.getOfferTypes();
    this.patchItemOfferFromStore();

    this.form.get('itemOffer').valueChanges.subscribe(
      itemOffer => {
        if (itemOffer instanceof ItemOffer) {
          this.isValidItemOffer = true;
          this.store.dispatch(new UpdateItemOffer({itemOffer: itemOffer}));
          this.setTemplatesAndPatchDefaultValue(itemOffer.templates);
        } else {
          this.isValidItemOffer = false;
        }
      }
    );

    this.form.get('template').valueChanges.subscribe(
      template => {
        this.store.dispatch(new UpdateItemOfferTemplate({itemOfferTemplate: template}));
      }
    );

    this.form.get('offerType')?.valueChanges.subscribe(value => {
      if (value) {
        this.isOfferTypeSelected = true;
      } else {
        this.isOfferTypeSelected = false;
      }

      this.filterOffersBySelectedType(value);
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetItemOffer());
    this.store.dispatch(new ResetItemOfferTemplate());
    if (this.currentPurchaseItemsSubscription) {
      this.currentPurchaseItemsSubscription.unsubscribe();
    }
    if (this.itemOfferSubscription) {
      this.itemOfferSubscription.unsubscribe();
    }
  }

  private patchItemOfferFromStore(): void | boolean {
    if (! this.purchaseItemId) {
      return false;
    }

    this.currentPurchaseItemsSubscription = this.store.select('currentPurchaseItems')
      .subscribe(
      (currentPurchaseItems) => {
        const items = currentPurchaseItems.display.concat(
          currentPurchaseItems.video,
          currentPurchaseItems.segmentalTv,
          currentPurchaseItems.ops,
        );
        const currentPurchaseItem = items.find(item => item.id == this.purchaseItemId);

        if (currentPurchaseItem && currentPurchaseItem['itemOffer']) {
          const itemOffer = new ItemOffer(currentPurchaseItem['itemOffer']);
          if (itemOffer && itemOffer.parentOffer && itemOffer.parentOffer.id) {
            this.form.get('offer').patchValue(itemOffer.parentOffer );
          } else {
            this.form.get('offer').patchValue(this.noOfferMsg);
          }
          this.form.get('itemOffer').patchValue(itemOffer);
          this.store.dispatch(new UpdateItemOffer({itemOffer: itemOffer}));

          this.isValidItemOffer = true;
          if (currentPurchaseItem['itemOfferTemplate']) {
            this.setTemplatesAndPatchDefaultValue(itemOffer.templates, currentPurchaseItem.itemOfferTemplate);
          }

          if (this.purchaseStep && this.purchaseStep.step_num === this.blockOfferStep) {
            this.form.get('offerType').disable();
            this.form.get('offer').disable();
            this.form.get('itemOffer').disable();
            this.form.get('template').disable();
          }
        }
      });
  }

  public getSelectedItemOffer(): object | boolean {
    const itemOffer = this.form.get('itemOffer').value;

    if (itemOffer && typeof itemOffer === 'object') {
      return itemOffer;
    }

    return false;
  }

  public getSelectedItemOfferTemplate(): OutputItemOfferTemplate | boolean {
    const itemOfferTemplate = this.form.get('template').value;

    if (itemOfferTemplate && itemOfferTemplate instanceof ItemOfferTemplate
      && this.templates && this.templates.length > 0) {
     return new OutputItemOfferTemplate(itemOfferTemplate);
    }
    return false;
  }

  public isValid(): boolean {
    this.form.get('itemOffer').markAsTouched();
    this.form.get('offer').markAsTouched();
    return this.form.valid;
  }

  private getOfferTypes(): void {
    this.offerTypeService
      .getList()
      .subscribe(
        offerTypes => {
          if (offerTypes) {
            this.offerTypes = offerTypes;
            this.filteredOfferTypes$ = of(offerTypes);
            this.filteredOfferTypes$ = this.form.get('offerType').valueChanges
            .pipe(
              startWith(''),
              map(value => this.offerTypes.filter(option =>
                option.title.toLowerCase().includes(value.toLowerCase())
              ))
            );
          }

          if (this.form.get('offer').value) {
            const selectedOfferType = this.offerTypes.find(offerType => offerType.id === this.form.get('offer').value.offerType);
            
            this.isOfferTypePreselected = true;
            this.isOfferTypeSelected = true;
            this.form.get('offerType').patchValue(
              selectedOfferType.title.charAt(0).toUpperCase() + selectedOfferType.title.slice(1)
            );
          }
          this.offerTypesLoading = false;
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue lors de la récupération des types d\'offres',
            null,
            { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
          );
      });
  }

  private getOffers(): void {
    this.offerService
      .getList({typeDispo: this.dispoType})
      .subscribe(
        offers => {
          if (offers) {
            this.offers = offers.filter(offer => offer.itemOffers && offer.itemOffers.length > 0);
            this.offers.forEach(offer => {
              offer.itemOffers = offer.itemOffers.filter(itemOffer => {
                return new Date(itemOffer.endDate) >= new Date();
              });
            });
            this.filteredOffers$ = of(offers);
            
            // Patch full offer value if there is a partial one from purchase item
            if (this.form.get('offer').value && this.form.get('offer').value.id
            && !this.form.get('offer').value.itemOffers && this.offers && this.offers.length > 0) {
              const newOfferValue = this.offers.find(newOffer => newOffer.id == this.form.get('offer').value.id);
              this.form.get('offer').patchValue(newOfferValue);
              
              // Get item offers of this offer into autocomplete options
              if (newOfferValue.itemOffers && newOfferValue.itemOffers.length > 0) {
                this.setAndFilterItemOfferAutocompleteOptions(newOfferValue.itemOffers);
              } 
            }
            
            // Filter offer autocomplete
            this.filteredOffers$ = this.form.get('offer').valueChanges
            .pipe(
              startWith(''),
              map(value => this._offerFilter(value))
            );

            this.offersLoading = false;
          }
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue lors de la récupération d\'offres',
            null,
            { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
          );
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      offerType: ['', [Validators.required]],
      offer: ['', [this.parentOfferValidator.bind(this)]],
      itemOffer: ['', [Validators.required, isObjectValidator]],
      template: ['']
    });
  }

  private _filter(value: string): ItemOffer[] {
    if (typeof value !== 'string') {
      return [];
    }

    const filterValue = value.toLowerCase();

    return this.itemOffers.filter(option =>
      option.title.toLowerCase().includes(filterValue) || option.id.toString().toLowerCase().includes(filterValue)
    );
  }

  public displayOfferType(value: OfferType): OfferType | string {
    return typeof value !== 'object' || !value ? value : `${value.title.charAt(0).toUpperCase() + value.title.slice(1)}`;
  }

  public displayItemOffer(value: ItemOffer): ItemOffer | string {
    return typeof value !== 'object' || !value ? value : `${value.title}`;
  }

  public displayOffer (value: Offer): Offer | string {
    return typeof value !== 'object' || !value ? value : `${value.title}`;
  }

  private _offerFilter(value: string): Offer[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();

    return this.offers.filter(option =>
      option.title.toLowerCase().includes(filterValue) || option.id.toString().toLowerCase().includes(filterValue)
    );
  }

  offerClickHandler(offer: Offer) {
    this.form.get('itemOffer').reset();
    this.setAndFilterItemOfferAutocompleteOptions(offer.itemOffers);
  }

  showOfferInfoPopUp() {
    if (this.form.get('itemOffer').value instanceof ItemOffer) {
      const dialogRef = this.dialog.open(ItemOfferInfoPopupComponent, {
        width: '300x',
        disableClose: false,
        data: {
          itemOffer: this.form.get('itemOffer').value,
        }
      });
    } else {
      this.snackBar.open(
        'Veillez choisir une offre.',
        null,
        { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
      );
    }

  }

  parentOfferValidator(control: FormControl): {[s: string]: boolean} {
    if (control.value === this.noOfferMsg || control.value instanceof Offer) {
      return null;
    } else {
      return {'offerIsNotValid': true};
    }
  }

  setTemplatesAndPatchDefaultValue(templatesToSort: ItemOfferTemplate[], defTemplate = null) {
    if (templatesToSort && templatesToSort.length > 0) {
      this.templates = templatesToSort.filter(template => template.bVisuCommerce);
      const defaultTemplate = defTemplate && defTemplate.id ? this.templates.find(temp => temp.id == defTemplate.id)
      : this.templates.find(template => template.position = 1);
      this.form.get('template').patchValue(defaultTemplate);
      this.store.dispatch(new UpdateItemOfferTemplate({itemOfferTemplate: defaultTemplate}));
    } else {
      this.templates = [];
      this.store.dispatch(new ResetItemOfferTemplate());
      this.store.dispatch(new UpdatePurchaseItemRef({itemOfferTemplate: null}));
    }
  }

  filterOffersBySelectedType(offerType: OfferType) {
    if (!this.isOfferTypePreselected) {
      this.form.get('offer').reset();
      this.form.get('itemOffer').reset();
    } else {
      this.isOfferTypePreselected = false;
    }

    if (!this.isOfferTypeSelected  && this.offers) {
      this.filteredOffers$ = of(this.offers);
    } else if (this.offers) {
      this.filteredOffers$ = of(this.offers.filter(offer => offer.offerType === offerType.id));
    }
  }

  setAndFilterItemOfferAutocompleteOptions(itemOffersToFilter) {
    this.itemOffers = itemOffersToFilter.filter(itemOffer => itemOffer.bVisuCommerce && itemOffer.bActif);
    this.filteredItemOffers$ = of(itemOffersToFilter);

    // Filter autocomplete
    this.filteredItemOffers$ = this.form.get('itemOffer').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }
}
