import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PurchaseRangeCategory} from '../../../core/model/purchase-range-category.model';
import {Store} from '@ngrx/store';
import {AppState, GetPurchaseRangeCategorySuccess, GetTypePackSuccess, PurchaseRangeCategoryState,} from '../../../store';
import {PurchaseRangeCategoryService} from '../../../core/service/purchase-range-category.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ListItemDialogComponent} from '../../shared/list-item-dialog/list-item-dialog.component';
import {Observable} from 'rxjs';
import {AppConstants} from '../../../app.constants';
import {TypePackService} from '../../../core/service/type-pack.service';
import {map, tap} from 'rxjs/operators';
import {EditMode, ItemOfferTemplate} from '../../../core/model/item-offer-template.model';
import {ListPopupComponent} from '../../shared/list-popup/list-popup.component';

@Component({
  selector: 'app-broadcast-range',
  templateUrl: './broadcast-range.component.html',
  styleUrls: ['./broadcast-range.component.scss']
})
export class BroadcastRangeComponent implements OnInit, OnDestroy {
  public broadcastRangeForm: FormGroup;
  public codeFormatDisplay;

  public categories$;
  public purchaseRangeCategories$: Observable<PurchaseRangeCategoryState>;

  public typeCategoriesFromTemplate = [];
  public rangesFromTemplate: PurchaseRangeCategory[] = [];

  public includedRangesFromTemplate: PurchaseRangeCategory[] = [];
  public limitedRangesFromTemplate: PurchaseRangeCategory[] = [];
  public excludedRangesFromTemplate: PurchaseRangeCategory[] = [];

  public includedRangeCategories: PurchaseRangeCategory[] = [];
  public limitedRangeCategories: PurchaseRangeCategory[] = [];
  public excludedRangeCategories: PurchaseRangeCategory[] = [];
  public category: any[] = [];

  public listBtnIsVisible = false;
  public templateListBtnIsVisible = false;
  public isValid: Boolean = true;
  public notInList: Boolean = false;
  public notInFromTemplateList = false;

  public loadingRanges = false;
  public anyRangeFromDbCanBeAdded = true;
  public rangesCanBeDeleted = true;
  public templateIsUsed = false;
  public hideRangesFromDbLine = false;
  public typeCategoriesSelectAndRangesFromTemplateInputAreVisible = false;
  @ViewChild('itemAutocompleteElement', {static: false}) itemAutocompleteElement: ElementRef;

  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeDisplay;
  public ranges = [];

  public allElementsArePreloaded = false;
  public isEquativ = false;

  constructor(
    private fb: FormBuilder,
    private purchaseRangeCategoryService: PurchaseRangeCategoryService,
    private storeApp: Store<AppState>,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private typePackService: TypePackService,
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.getTypeCategories();

    this.categories$ = this.storeApp.select(state => state.typePack);
    this.purchaseRangeCategories$ = this.storeApp.select(state => state.purchaseRangeCategory);

    this.storeApp.select(state => {
      let items: any = null;
      if (this.dispoType == AppConstants.typeDisplay) {
        items = state.currentPurchaseItems.display;
      } else if (this.dispoType == AppConstants.typeVideo) {
        items = state.currentPurchaseItems.video;
      }

      if (this.purchaseItemId && items && items.length > 0) {
        for (const p of items) {
          if (p.id == this.purchaseItemId) {
            return p.formats[0].codFormat.id;
          }
        }
      }

      return null;
    }).subscribe(
      (val) => {
        this.codeFormatDisplay = val;
      }
    );
    this.broadcastRangeForm.get('rangeType').valueChanges.subscribe(value => {
      this.pushRangesOfCertainSelectionToRangesFromTemplateArray(value, true);
      this.broadcastRangeForm.get('categoryFromTemplate').patchValue('all');
    });

    this.subscribeItems();

    this.getPurchaseRangeItem();

    this.getPurchaseRangeCategories();

    this.reloadRanges(this.purchaseItemId);

    this.subscribeToValueChangeCategoryFromTemplate();

    this.subscribeToValueChangeItemFromTemplate();

    this.isEquativItem();
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.notInList = false;
  }

  public initForm(): void {
    this.broadcastRangeForm = this.fb.group({
      rangeType: ['included'],
      category: ['all'],
      item: [''],
      categoryFromTemplate: ['all'],
      itemFromTemplate: ['']
    });
  }

  private getTypeCategories(): void {
    this.typePackService
      .getList()
      .subscribe(typePack => {
        this.storeApp.dispatch(new GetTypePackSuccess(typePack));
      });
  }

  public displayFn(purchaseRangeCategory: PurchaseRangeCategory): string {
    return purchaseRangeCategory instanceof PurchaseRangeCategory ? `${purchaseRangeCategory.name} (${purchaseRangeCategory.id})` : '';
  }

  public clearItem(): void {
    this.broadcastRangeForm.get('item').clearValidators();
    this.broadcastRangeForm.get('item').patchValue('');
  }

  public clearItemFromTemplate(): void {
    this.broadcastRangeForm.get('itemFromTemplate').clearValidators();
    this.broadcastRangeForm.get('itemFromTemplate').patchValue('');
  }

  public addToRangeArray(pPurchaseRangeCategory: PurchaseRangeCategory = null, pRangeType: string = null) {
    let rangeType = null;
    pRangeType == null ?
      rangeType = this.broadcastRangeForm.get('rangeType').value :
      rangeType = pRangeType;

    const purchaseRangeCategory = pPurchaseRangeCategory || this.broadcastRangeForm.get('item').value;
    this.notInList = false;

    if (!(purchaseRangeCategory instanceof PurchaseRangeCategory)) {
      this.notInList = true;
      return;
    }

    this.pushToRangeArrays(rangeType, purchaseRangeCategory, false);
    this.isValid = true;
  }

  private isRangeAllreadyIncluded(purchaseRangeCategory: PurchaseRangeCategory): boolean {
    if (this.includedRangeCategories.find(data => data.id === purchaseRangeCategory.id)) {
      this.snackBar.open(
        'Déjà présent dans "Diffuser sur"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }

    if (this.limitedRangeCategories.find(data => data.id === purchaseRangeCategory.id)) {
      this.snackBar.open(
        'Déjà présent dans "Limiter votre sélection à"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }

    if (this.excludedRangeCategories.find(data => data.id === purchaseRangeCategory.id)) {
      this.snackBar.open(
        'Déjà présent dans "Ne pas diffuser sur"',
        null,
        {duration: 2000, verticalPosition: 'top'}
      );
      return true;
    }
  }

  public clearAll(rangeType: string): void {
    switch (rangeType) {
      case 'included':
        this.includedRangeCategories = [];
        break;
      case 'limited':
        this.limitedRangeCategories = [];
        break;
      case 'excluded':
        this.excludedRangeCategories = [];
        break;
      case 'all':
        this.includedRangeCategories = [];
        this.limitedRangeCategories = [];
        this.excludedRangeCategories = [];
        break;
    }
  }

  public openFullListDialog(): void {
    const preselectedRanges = this.chooseRangesBySelectionTypeAndTypaCategory();;
    const dialogRef = this.dialog.open(ListItemDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        modalTitle: this.broadcastRangeForm.get('category').value.label,
        alreadyAddedRanges: preselectedRanges,
        type: 'purchaseRangeCategory',
        route: 'digital/purchase_range_category',
        filters: {
          typeCategory: this.broadcastRangeForm.get('category').value.label,
          codeFormat: this.codeFormatDisplay
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        result.forEach(purchaseRangeCategory => this.addToRangeArray(purchaseRangeCategory));
      }
    });
  }

  public getCategoryNameByTypeCategory(typeCategory: string): Observable<string> {
    return new Observable (observer => {
      this.categories$.subscribe(
        categories => {
          if (categories) {
            observer.next((categories.find((cat) => cat.label === typeCategory).label));
          }
        },
        error => observer.error(this.catchError(error)),
      );
    });
  }

  public getBroadcastRange() {
    this.isValid = !!this.includedRangeCategories.length;

    if (this.isValid) {
      return {
        'included': this.includedRangeCategories,
        'limited': this.limitedRangeCategories,
        'excluded': this.excludedRangeCategories
      };
    } else {
      this.snackBar.open(
        'Veuillez inclure un périmètre de diffusion.',
        null,
        {duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
      );
    }

    return false;
  }

  private subscribeItems(): void {
    this.storeApp.select('currentPurchaseItems')
      .subscribe(currentPurchaseItems => {
        if (!this.purchaseItemId) {
          return;
        }

        let purchaseItems: any = null;
        if (this.dispoType === AppConstants.typeDisplay) {
          purchaseItems = currentPurchaseItems.display;
        } else if (this.dispoType === AppConstants.typeVideo) {
          purchaseItems = currentPurchaseItems.video;
        }

        if (!purchaseItems) {
          return;
        }

        for (const purchaseItem of purchaseItems) {


          if (purchaseItem
            && purchaseItem.id == this.purchaseItemId) {

            if (purchaseItem.itemOfferTemplate && purchaseItem.itemOfferTemplate.id) {
              let template = purchaseItem.itemOfferTemplate;
              if (template._embedded && template._embedded && !template.diffusionRanges) {
                template = new ItemOfferTemplate(template);
              }

              switch (template.diffusionRangeEditMode) {
                case EditMode.Full:
                  this.rangesCanBeDeleted = true;
                  this.anyRangeFromDbCanBeAdded = true;
                  this.templateIsUsed = true;
                  this.hideRangesFromDbLine = true;
                  break;
                case EditMode.Partial:
                  this.rangesCanBeDeleted = true;
                  this.anyRangeFromDbCanBeAdded = false;
                  this.templateIsUsed = true;
                  break;
                case EditMode.Off:
                  this.rangesCanBeDeleted = false;
                  this.anyRangeFromDbCanBeAdded = false;
                  this.templateIsUsed = true;
                  break;
              }

              if (template.diffusionRangeEditMode !== EditMode.Off
                && template.diffusionRanges
                && ((template.diffusionRanges.included && template.diffusionRanges.included.length > 0)
                  || (template.diffusionRanges.limited && template.diffusionRanges.limited.length > 0))) {
                this.purchaseRangeCategoryService.getList({
                  categoryType: 'all',
                  codeFormat: purchaseItem.formats[0].codFormat.id,
                  allResults: true,
                }).subscribe(result => {
                  if (result.items && result.items.length > 0) {
                    if (template.diffusionRanges.included
                      && template.diffusionRanges.included.length > 0) {
                      this.pushTemplateRangesToLocalArrays('included',
                        template.diffusionRanges.included, result.items);
                    }
                    if (template.diffusionRanges.limited
                      && template.diffusionRanges.limited.length > 0) {
                      this.pushTemplateRangesToLocalArrays('limited',
                        template.diffusionRanges.limited, result.items);
                    }
                    if (template.diffusionRanges.excluded
                      && template.diffusionRanges.excluded.length > 0) {
                      this.pushTemplateRangesToLocalArrays('excluded',
                        template.diffusionRanges.excluded, result.items);
                    }
                    this.pushRangesOfCertainSelectionToRangesFromTemplateArray(this.broadcastRangeForm.get('rangeType').value);
                    this.allElementsArePreloaded = true;
                  }
                });
              } else if (template.diffusionRangeEditMode !== EditMode.Off) {
                this.setDefaultsWhenNoRangesFromTemplate();
              } else {
                this.allElementsArePreloaded = true;
              }
            } else {
              this.setDefaultsWhenNoRangesFromTemplate();
            }
            if (purchaseItem.ranges.length > 0) {
              this.pushRangesFromPurchaseItem(purchaseItem.ranges);
            }
            break;
          }
        }

      });
  }

  private extractRange(range: any): PurchaseRangeCategory {
    return new PurchaseRangeCategory({
      id: range.categoryId,
      name: range.category ? range.category.name : '',
      typeCategory: range.categoryType.label,
      codeFormat: this.codeFormatDisplay
    });
  }

  public reloadRanges(purchaseItemId: number = null): void {
    this.purchaseItemId = purchaseItemId;
  }

  ngOnDestroy() {
    this.purchaseItemId = null;
  }

  public getPurchaseRangeItem(): void {
    this.broadcastRangeForm
      .get('item')
      .valueChanges
      .startWith(null)
      .debounceTime(400)
      .filter(value => value && value.length >= 2)
      .filter(value => typeof value !== 'object')
      .pipe(tap(() => this.loadingRanges = true))
      .pipe(tap(() => this.itemAutocompleteElement.nativeElement.click()))
      .subscribe(value => {
        const categoryType = this.broadcastRangeForm.get('category').value.label || 'all';
        Observable.forkJoin(
          this.purchaseRangeCategoryService.getList({
            categoryType: categoryType,
            name: value,
            codeFormat: this.codeFormatDisplay
          }),
          this.purchaseRangeCategoryService.getList({
            categoryType: categoryType,
            id: value,
            codeFormat: this.codeFormatDisplay
          }),
        )
          .pipe(map(data => data[0]['items'].concat(data[1]['items'])))
          .pipe(tap(() => this.loadingRanges = false))
          .subscribe(data => this.storeApp.dispatch(new GetPurchaseRangeCategorySuccess(data)));
      });
  }

  public getPurchaseRangeCategories(): void {
    this.broadcastRangeForm
      .get('category')
      .valueChanges
      .subscribe(data => {
        this.storeApp.dispatch(new GetPurchaseRangeCategorySuccess([]));
        this.broadcastRangeForm.get('item').patchValue('');
        this.listBtnIsVisible = !(this.broadcastRangeForm.get('category').value === 'all');
      });
  }

  /**
   * @param error
   * @param {string} routeRedirect
   */
  private catchError(error: any): void {
    switch (error.status) {
      default:
        this.snackBar.open(
          'erreur lors de la récupération des catégories',
          null,
          {duration: 2000, verticalPosition: 'top'}
        );
        break;
    }

    return error;
  }

  clearSearchInput() {
    this.broadcastRangeForm.get('item').reset();
  }

  private pushTemplateRangesToLocalArrays(selectionType, templateRanges: PurchaseRangeCategory[], dbRanges) {
    if (templateRanges.length < 0 || dbRanges.length < 0) {
      return;
    }
    const preparedRanges = dbRanges.filter(dbRange => {
      return templateRanges.find(range => range.id === dbRange.id);
    });

    switch (selectionType) {
      case 'included':
        this.includedRangesFromTemplate = preparedRanges;
        break;
      case 'limited':
        this.limitedRangesFromTemplate = preparedRanges;
        break;
      case 'excluded':
        this.excludedRangesFromTemplate = preparedRanges;
        break;
    }
  }

  private extractTypeCategoriesFromTemplate(purchaseCategories: PurchaseRangeCategory[]) {
    this.typeCategoriesFromTemplate = [];
    if (!purchaseCategories || purchaseCategories.length === 0) {
      this.typeCategoriesSelectAndRangesFromTemplateInputAreVisible = false;
      return;
    }

    this.typeCategoriesSelectAndRangesFromTemplateInputAreVisible = true;
    purchaseCategories.map(purchaseCategory => {
      if (purchaseCategory.typeCategory) {
        if (this.typeCategoriesFromTemplate.length > 0) {
          const alreadyPresentCategory = this.typeCategoriesFromTemplate.find(cat => cat === purchaseCategory.typeCategory);
          if (!alreadyPresentCategory) {
            this.typeCategoriesFromTemplate.push(purchaseCategory.typeCategory);
          }
        } else {
          this.typeCategoriesFromTemplate.push(purchaseCategory.typeCategory);
        }
      }
    });
  }

  private pushRangesOfCertainSelectionToRangesFromTemplateArray(selection: string, extract = true) {
    switch (selection) {
      case 'included':
        if (extract) {
          this.extractTypeCategoriesFromTemplate(this.includedRangesFromTemplate);
        }
        this.rangesFromTemplate = this.includedRangesFromTemplate;
        break;
      case 'limited':
        if (extract) {
          this.extractTypeCategoriesFromTemplate(this.limitedRangesFromTemplate);
        }
        this.rangesFromTemplate = this.limitedRangesFromTemplate;
        break;
      case 'excluded':
        if (extract) {
          this.extractTypeCategoriesFromTemplate(this.excludedRangesFromTemplate);
        }
        this.rangesFromTemplate = this.excludedRangesFromTemplate;
        break;
    }
  }

  addTemplateRangeFromInput() {
    const range = this.broadcastRangeForm.controls['itemFromTemplate'].value;
    if ( range instanceof PurchaseRangeCategory) {
      this.pushToRangeArrays(this.broadcastRangeForm.controls['rangeType'].value, range, true);
      this.notInFromTemplateList = false;
    } else {
      this.notInFromTemplateList = !!range;
    }
  }

  clearSearchInputFromTemplate() {
    this.clearItemFromTemplate();
    this.notInFromTemplateList = false;
  }

  pushToRangeArrays(seletionType: string, range: PurchaseRangeCategory, isItemFromTemplate = false, pushDirectly = false) {
    switch (seletionType) {
      case 'included':
        if (pushDirectly) {
          this.includedRangeCategories.push(range);
        } else {
          if (!this.isRangeAllreadyIncluded(range)) {
            this.includedRangeCategories.push(range);
          }
        }
        break;
      case 'limited':
        if (pushDirectly) {
          this.limitedRangeCategories.push(range);
        } else {
          if (!this.isRangeAllreadyIncluded(range)) {
            this.limitedRangeCategories.push(range);
          }
        }
        break;
      case 'excluded':
        if (pushDirectly) {
          this.excludedRangeCategories.push(range);
        } else {
          if (!this.isRangeAllreadyIncluded(range)) {
            this.excludedRangeCategories.push(range);
          }
        }

        break;
    }
    isItemFromTemplate ?  this.clearItemFromTemplate() : this.clearItem();
  }

  private subscribeToValueChangeCategoryFromTemplate() {
    this.broadcastRangeForm.get('categoryFromTemplate').valueChanges
      .subscribe(value => {
        this.pushRangesOfCertainSelectionToRangesFromTemplateArray(this.broadcastRangeForm.get('rangeType').value, false);
        if (value === 'all') {
          this.templateListBtnIsVisible = false;
        } else {
          if (this.rangesFromTemplate && this.rangesFromTemplate.length > 0) {
            this.rangesFromTemplate = this.rangesFromTemplate.filter(range => range.typeCategory === value);
          }
          this.templateListBtnIsVisible = true;
        }
      });
  }

  private subscribeToValueChangeItemFromTemplate() {
    this.broadcastRangeForm.get('itemFromTemplate').valueChanges.subscribe(value => {
      const typeCategory = this.broadcastRangeForm.get('categoryFromTemplate').value;

      let filterByCategory = false;
      if (typeCategory !== 'all') {
        filterByCategory = true;
      }
      if (typeof value  === 'string') {
        switch (this.broadcastRangeForm.get('rangeType').value) {
          case 'included':
            this.rangesFromTemplate = this.includedRangesFromTemplate.filter(range =>
              filterByCategory
                ? range.name.toLowerCase().includes(value.toLowerCase()) && range.typeCategory === typeCategory
                : range.name.toLowerCase().includes(value.toLowerCase())
            );
            break;
          case 'limited':
            this.rangesFromTemplate = this.limitedRangesFromTemplate.filter(range =>
              filterByCategory
                ? range.name.toLowerCase().includes(value.toLowerCase()) && range.typeCategory === typeCategory
                : range.name.toLowerCase().includes(value.toLowerCase())
            );
            break;
          case 'excluded':
            this.rangesFromTemplate = this.excludedRangesFromTemplate.filter(range =>
              filterByCategory
                ? range.name.toLowerCase().includes(value.toLowerCase()) && range.typeCategory === typeCategory
                : range.name.toLowerCase().includes(value.toLowerCase())
            );
            break;
        }
      }
    });
  }

  private pushRangesFromPurchaseItem(ranges) {
    this.includedRangeCategories = [];
    this.limitedRangeCategories = [];
    this.excludedRangeCategories = [];

    ranges.forEach(range => {
      if (range.selectionType.id === 'INCLUDE') {
        this.includedRangeCategories.push(this.extractRange(range));
      }

      if (range.selectionType.id === 'FILTER') {
        this.limitedRangeCategories.push(this.extractRange(range));
      }

      if (range.selectionType.id === 'EXCLUDE') {
        this.excludedRangeCategories.push(this.extractRange(range));
      }
    });
  }

  public openListPopup() {
    const preselectedRanges = this.chooseRangesBySelectionTypeAndTypaCategory();
    const dialogRef = this.dialog.open(ListPopupComponent, {
      width: '600px',
      disableClose: false,
      data: {
        title: this.broadcastRangeForm.get('categoryFromTemplate').value === 'all'
          ? 'catégories'
          : this.broadcastRangeForm.get('categoryFromTemplate').value,
        items: this.rangesFromTemplate,
        preselectedItems: preselectedRanges,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.length > 0) {
        this.filterRangeArrayOfCertainSelection(result);
        result.forEach(purchaseRangeCategory => {
          const alreadyInArrayRange = preselectedRanges.find(existingRange => existingRange.id == purchaseRangeCategory.id);
          if (!alreadyInArrayRange) {
            this.pushToRangeArrays(this.broadcastRangeForm.get('rangeType').value, purchaseRangeCategory, true, true);
          }
        });
      }
    });
  }

  private filterRangeArrayOfCertainSelection (updatedRanges) {
    switch (this.broadcastRangeForm.get('rangeType').value) {
      case 'included':
        this.includedRangeCategories = this.includedRangeCategories.filter(range => {
          if (this.broadcastRangeForm.get('categoryFromTemplate').value === range.typeCategory) {
            return updatedRanges.find(newRange => newRange.id === range.id);
          }else {
            return true;
          }
        });
        break;
      case 'limited':
        this.limitedRangeCategories = this.limitedRangeCategories.filter(range => {
          if (this.broadcastRangeForm.get('categoryFromTemplate').value === range.typeCategory) {
            return updatedRanges.find(newRange => newRange.id === range.id);
          }else {
            return true;
          }
        });
        break;
      case 'excluded':
        this.excludedRangeCategories = this.excludedRangeCategories.filter(range => {
          if (this.broadcastRangeForm.get('categoryFromTemplate').value === range.typeCategory) {
            return updatedRanges.find(newRange => newRange.id === range.id);
          }else {
            return true;
          }
        });
        break;
    }
  }

  private chooseRangesBySelectionTypeAndTypaCategory(): Array<PurchaseRangeCategory> {
    switch (this.broadcastRangeForm.get('rangeType').value) {
      case 'included':
        return this.includedRangeCategories
          .length > 0 ? this.includedRangeCategories
          .filter(includedRange => includedRange.typeCategory === this.broadcastRangeForm.get('categoryFromTemplate').value) : [];
      case 'limited':
        return this.limitedRangeCategories
          .length > 0 ? this.limitedRangeCategories
          .filter(limitedRange => limitedRange.typeCategory === this.broadcastRangeForm.get('categoryFromTemplate').value) : [];
      case 'excluded':
        return this.excludedRangeCategories
          .length > 0 ? this.excludedRangeCategories
          .filter(excludedRange => excludedRange.typeCategory === this.broadcastRangeForm.get('categoryFromTemplate').value) : [];
    }
  }

  public checkIfValueIsRange(inputFromTemplate: boolean): boolean {
    if (inputFromTemplate) {
      return this.broadcastRangeForm.controls['itemFromTemplate'].value instanceof PurchaseRangeCategory;
    } else {
      return this.broadcastRangeForm.controls['item'].value instanceof PurchaseRangeCategory;
    }
  }

  public showdbRangesLine() {
    this.hideRangesFromDbLine = !this.hideRangesFromDbLine;
  }

  private setDefaultsWhenNoRangesFromTemplate () {
    this.templateIsUsed = false;
    this.rangesCanBeDeleted= true;
    this.anyRangeFromDbCanBeAdded = true;
    this.allElementsArePreloaded = true;
    this.hideRangesFromDbLine = false;
    this.rangesFromTemplate = [];
    this.typeCategoriesFromTemplate = [];
    this.typeCategoriesSelectAndRangesFromTemplateInputAreVisible = false;
  }

  public isEquativItem(): void {
    // todo: get list of equativ sites
    let equativSites = ['', ''];
    this.storeApp.select(state => {
      if (this.dispoType == AppConstants.typeDisplay) {
        if (this.purchaseItemId && state.currentPurchaseItems.display 
              && state.currentPurchaseItems.display.length > 0) {
          for (const p of state.currentPurchaseItems.display) {
            if (p.id == this.purchaseItemId && p.ranges.length) {
              const ranges = p.ranges;
              for (const r of ranges) {
                if (r.selectionType.id.toUpperCase() === 'INCLUDE'
                    && r.categoryType.id.toUpperCase() === 'WM_SITE'
                  ) {
                    return true;
                  }
              }
            }
          }
        }
      }      

      return false;
    }).subscribe(
      (val) => {
        this.isEquativ = val;
      }
    );
  }
}
