export interface JsonOfferType {
  id: number;
  title: string;
}

export class OfferType {
  public id: number;
  public title: string;

  constructor (jsonOfferType: JsonOfferType) {
    this.id = jsonOfferType.id;
    this.title = jsonOfferType.title;
  }
}
