import {ItemOffer} from './item-offer.model';

export interface JsonOffer {
  id: number;
  title: string;
  typeDispo: number;
  offerType: number;
  itemOffers: any;
}

export class Offer {
  public id: number;
  public title: string;
  public typeDispo: number;
  public offerType: number;
  public itemOffers?: ItemOffer[];

  constructor (jsonOffer: JsonOffer, addItemOffers: boolean = false) {
    this.id = jsonOffer.id;
    this.title = jsonOffer.title;
    this.typeDispo = jsonOffer.typeDispo;
    this.offerType = jsonOffer.offerType;
    if (addItemOffers  && jsonOffer.itemOffers) {
      this.itemOffers =  jsonOffer.itemOffers.map(jsonItemOffer => {
        return new ItemOffer(jsonItemOffer);
      });
    }
  }
}
