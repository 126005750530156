import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfferType } from '../model/offer-type.model';
import { HttpClient } from "@angular/common/http";

export interface OfferTypeFilter {
  id?: number;
  title?: string;
}

@Injectable()
export class OfferTypeService {

  private route = '/digital/offer_type';

  constructor(
    private httpService: HttpClient,
    private snackBar: MatSnackBar
  ) {
  }

  public getList(filter: OfferTypeFilter = {}): Observable<OfferType[]> {
    let url = environment.adspace_api_base_url + this.route;
    const params = new URLSearchParams();

    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        params.set(key, filter[key]);
      }
    }

    url += '?' + params.toString();

    return new Observable (observer => {
      this.httpService.get(url)
        .pipe(
          map((jsonResponse: any[]) => {
            const offerTypes = jsonResponse
              .map((jsonOfferType: any) => new OfferType(jsonOfferType));
            return offerTypes;
          })
        ).subscribe(
          (response: any) => observer.next(response),
          error => observer.error(this.catchError(error))
        );
    });
  }

  private catchError(error: any): void {
    this.snackBar.open('Une erreur est survenue lors de la récupération des types d\'offre', 'Close', {
      duration: 5000,
      panelClass: ['error-snackbar']
    });
  }
}
