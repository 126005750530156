import {Component, DoCheck, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OfferService} from '../../../core/service/offer.service';
import {OfferTypeService} from '../../../core/service/offer-type.service';
import {Offer} from '../../../core/model/offer.model';
import {OfferType} from '../../../core/model/offer-type.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CreateParentOfferPopupComponent} from './create-parent-offer-popup/create-parent-offer-popup.component';
import {FormatService} from '../../../core/service/format.service';
import * as moment from 'moment';
import {ItemOfferService} from '../../../core/service/item-offer.service';
import {Format} from '../../../core/model/format.model';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ItemOffer} from '../../../core/model/item-offer.model';
import {AppConstants} from '../../../app.constants';
import {ItemOfferSalesService} from '../../../core/service/item-offer-sales.service';
import {ItemOfferSales} from '../../../core/model/item-offer-sales.model';
import {EditMode, ItemOfferTemplate} from '../../../core/model/item-offer-template.model';
import {FwCappingService} from '../../../core/service/fw-capping.service';
import {CappingService} from '../../../core/service/capping.service';
import {FwTypeCaping, TypeCaping} from '../../../core/model/capping.model';
import {DiffusionModeService} from '../../../core/service/diffusion-mode.service';
import {DiffusionMode} from '../../../core/model/diffusion-mode.model';
import {ItemOfferTemplateService} from '../../../core/service/item-offer-template.service';
import {TemplateReorderDialogComponent} from './template-reorder-dialog/template-reorder-dialog.component';

@Component({
  selector: 'app-inventory-manage-offer',
  templateUrl: './inventory-manage-offer.component.html',
  styleUrls: ['./inventory-manage-offer.component.scss']
})

export class InventoryManageOfferComponent implements OnInit {
  public title = 'Ajouter une offre';
  private id: string;
  // To pass into template
  public typeDispoValue: any = '';
  public offerTypeValue: any = '';
  public offerToEdit: ItemOffer;
  public itemOfferForm: FormGroup;
  public numberOfCharactersLeft = 4000;
  public dispoTypes = [
    {
      label: 'Vidéo',
      value: AppConstants.typeVideo,
    },
    {
      label: 'Display',
      value: AppConstants.typeDisplay,
    },
    {
      label: 'OPS',
      value: AppConstants.typeOps,
    },
    {
      label: 'Tv Segmentée',
      value: AppConstants.typeSegmentalTv,
    },
  ];
  public parentOffers: Offer[] = [];
  public offerTypes: OfferType[] = [];
  public preSelectedParentOffers: Offer [] = [];
  public preSelectedTypeOffers: Offer [] = [];
  // Property to store formats from db
  public validFormats = [];
  public fwCappingTypes = Array<FwTypeCaping>();
  public cappingTypes = Array<TypeCaping>();
  public diffusionModes = Array<DiffusionMode>();
  public isSaving = false;
  public isLoading = false;
  private defaultSartDate = moment(new Date).format('YYYY-MM-DD') + ' 00:00';
  private defaultEndDate = new Date().getFullYear() + '-12-31 00:00';

  public currentYear: any = new Date().getFullYear();
  public currentYearRevenue = 0;
  public currentYearNumberOfSales = 0;
  public lastYear: any = this.currentYear - 1;
  public lastYearNumberOfSales = 0;
  public lastYearRevenue = 0;

  constructor(
    private fb: FormBuilder,
    private offerService: OfferService,
    private offerTypeService: OfferTypeService,
    private itemOfferService: ItemOfferService,
    private itemOfferSalesService: ItemOfferSalesService,
    private fwCappingService: FwCappingService,
    private cappingService: CappingService,
    private formatService: FormatService,
    private diffusionModeService: DiffusionModeService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private itemOfferTemplateService: ItemOfferTemplateService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.initForm();
    this.getItemOfferToEditFromDBAndPreFillInfo();
    this.getOfferTypes();
    this.getParentOffers();
    this.subscribeDependingValues();
    this.limitAndCountCharachtersInTextArea();

  }

  private initForm(): void {
    this.itemOfferForm = this.fb.group({
      typeDispo: ['', Validators.required],
      offerType: ['', Validators.required],
      isActive: [true],
      isCommerceAccesible: [true],
      parentOffer: ['', Validators.required],
      title: ['',
        [
          Validators.required,
          Validators.maxLength(250)
        ]],
      link: ['', [Validators.maxLength(255)]],
      description: ['',
        [
          Validators.maxLength(4000)
        ]],
      startDate: [this.defaultSartDate],
      endDate: [this.defaultEndDate]
    });
  }

  openCreateOfferPopUp() {
    const dialogRef = this.dialog.open(CreateParentOfferPopupComponent, {
      width: '300x',
      disableClose: false,
      data: {
        dispoTypes: this.dispoTypes,
        offerTypes: this.offerTypes,
        typeDispo: this.itemOfferForm.get('typeDispo').value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.parentOffers.push(result);
        this.itemOfferForm.get('typeDispo').patchValue(result.typeDispo);
        this.itemOfferForm.get('offerType').patchValue(result.offerType);
        this.itemOfferForm.get('parentOffer').patchValue(result.id);
      }
    });
  }

  private getParentOffers() {
    this.offerService.getList()
      .subscribe(
        data => {
          if (data) {
            this.parentOffers = data;
            if (this.itemOfferForm.get('typeDispo').value !== '') {
              if (this.parentOffers.length > 0) {
                this.preSelectedParentOffers =
                  this.parentOffers.filter(offer => offer.typeDispo === this.itemOfferForm.get('typeDispo').value,);
              }
            }

            if (this.offerToEdit?.parentOffer) {
                this.itemOfferForm.get('offerType').patchValue(this.offerToEdit.parentOffer.offerType);
            }

            if (this.itemOfferForm.get('offerType').value !== '') {
              if (this.preSelectedParentOffers.length > 0) {
                this.preSelectedParentOffers = 
                  this.preSelectedParentOffers.filter(offer => offer.offerType === this.itemOfferForm.get('offerType').value);
              }
            }
          }

          
          this.isLoading = false;
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
        }
      );
  }
  
  private getOfferTypes(): void {
    this.offerTypeService.getList()
      .subscribe(
        data => {
          if (data) {
            this.offerTypes = data;
          }
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue lors de la récupération des types d\'offre',
            null,
            { duration: 2000, verticalPosition: 'top'}
          );
        }
      );
  }

  saveOffer() {
    if (this.itemOfferForm.valid) {
      this.isSaving = true;
      const itemOffer = {
        title: this.itemOfferForm.value.title,
        parentOffer: this.itemOfferForm.value.parentOffer,
        startDate: this.itemOfferForm.value.startDate ?
          moment(this.itemOfferForm.value.startDate).format('YYYY-MM-DD HH:mm') :
          this.defaultSartDate,
        endDate: this.itemOfferForm.value.endDate ?
          moment(this.itemOfferForm.value.endDate).format('YYYY-MM-DD HH:mm') :
          this.defaultEndDate,
        bActif: this.itemOfferForm.value.isActive ? 1 : 0,
        bVisuCommerce: this.itemOfferForm.value.isCommerceAccesible ? 1 : 0,
        description: this.itemOfferForm.value.description ? this.itemOfferForm.value.description : '',
        link: this.itemOfferForm.value.link ? this.itemOfferForm.value.link : '',
      };

      if (this.id && this.offerToEdit) {
        /* Update existing offer */
        this.itemOfferService.update(this.id, itemOffer).subscribe(
          response => {
            this.isSaving = false;
            this.snackBar.open(
              'Offre ' + itemOffer.title + ' a été modifiée.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            if (response instanceof ItemOffer) {
              this.offerToEdit = response;
              this.itemOfferForm.get('typeDispo').disable();
              this.title = this.offerToEdit.title;
            }
          },
          () => {
            this.isSaving = false;
            this.snackBar.open(
              'Offre ' + itemOffer.title + ' n\'a pas été modifiée.',
              null,
              { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
            );
          }
        );
      } else {
        /* Create new offer */
        this.itemOfferService.create(itemOffer).subscribe(
          response => {
            this.isSaving = false;
            this.snackBar.open(
              'Offre Niveau 2 a été créée.',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
            if (response && response.id) {
              this.router.navigate(['inventory/offer/edit/' + response.id]);
            }
          },
          () => {
            this.isSaving = false;
            this.snackBar.open(
              'Offre Niveau 2 n\'a pas été créée.',
              null,
              { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
            );
          }
        );

      }


    } else {
      this.snackBar.open(
        'Veuillez renseigner toutes les informations',
        null,
        { duration: 2000, verticalPosition: 'top'}
      );
    }
  }

  getAllCappingTypes(dispoType: number) {
    switch (dispoType) {
      case AppConstants.typeVideo:
      case AppConstants.typeSegmentalTv:
        this.fwCappingService.getList().subscribe(
          response => {
            this.fwCappingTypes = response;
          },
          () => {
            this.snackBar.open(
              'Une erreur est survenue',
              null,
              { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
            );
          }
        );
        this.getTypeCappingsByDispo(dispoType);
        break;
      case AppConstants.typeDisplay:
        this.getTypeCappingsByDispo(dispoType);
        break;
      default: break;
    }
  }

  getTypeCappingsByDispo(dispoType: number): void {
    this.cappingService.getList({dispoType: dispoType})
      .subscribe(
        response => {
          this.cappingTypes = response;
        },
        () => {
          this.snackBar.open(
            'Une erreur est survenue',
            null,
            { duration: 2000, verticalPosition: 'top', panelClass: ['chip-error']}
          );
        }
      );
  }

  getItemOfferToEditFromDBAndPreFillInfo(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id) {
        this.itemOfferService.get(this.id, 'inventory')
          .subscribe(data => {
              if (data instanceof ItemOffer) {
                this.offerToEdit = data;
                this.title = this.offerToEdit.title;
                if (this.offerToEdit.parentOffer) {
                  this.itemOfferForm.get('typeDispo').patchValue(this.offerToEdit.parentOffer.typeDispo);
                  this.itemOfferForm.get('typeDispo').disable();
                  this.itemOfferForm.get('offerType').patchValue(this.offerToEdit.parentOffer.offerType);
                  this.itemOfferForm.get('parentOffer').patchValue(this.offerToEdit.parentOffer.id);
                }
                this.itemOfferForm.get('isActive').patchValue(this.offerToEdit.bActif);
                this.itemOfferForm.get('isCommerceAccesible').patchValue(this.offerToEdit.bVisuCommerce);
                this.itemOfferForm.get('title').patchValue(this.offerToEdit.title);
                this.itemOfferForm.get('link').patchValue(this.offerToEdit.link);
                this.itemOfferForm.get('description').patchValue(this.offerToEdit.description);
                this.itemOfferForm.get('startDate').patchValue(this.offerToEdit.startDate);
                this.itemOfferForm.get('endDate').patchValue(this.offerToEdit.endDate);
              }
            },
            () => {
              this.isLoading = false;
              this.snackBar.open(
                'Offre n\'a pas été chargée.',
                null,
                { duration: 2000, verticalPosition: 'top'}
              );
            });

        this.itemOfferSalesService.get(this.id).subscribe(
          data => {
            if (data instanceof ItemOfferSales) {
              this.currentYear = data.currentYear;
              this.currentYearRevenue = data.currentYearRevenue;
              this.currentYearNumberOfSales = data.currentYearSalesNumber;
              this.lastYear = data.lastYear;
              this.lastYearRevenue = data.lastYearRevenue;
              this.lastYearNumberOfSales = data.lastYearSalesNumber;
            }
          },
          () => {
            this.snackBar.open(
              'Les ventes ne sont pas étées récupérées',
              null,
              { duration: 2000, verticalPosition: 'top'}
            );
          } );
      }
    });
  }

  /* Function subscribes to typeDispo value change and ensures that:
1. parent offers get preselected according to this type
2. formats get preselected according to this type
3. correct cappingtypes get loaded
4. correct dissusion mode gets loaded
5. offer type get preselected
*/
  subscribeDependingValues(): void {
    this.itemOfferForm.get('typeDispo').valueChanges.subscribe(value => {
      this.typeDispoValue = value;

      /* Parent Offer Preselection */
      if (this.offerToEdit && this.offerToEdit.parentOffer
        && this.offerToEdit.parentOffer.typeDispo === value
        && this.offerToEdit.parentOffer.id) {
        this.itemOfferForm.get('parentOffer').patchValue(this.offerToEdit.parentOffer.id);
      } else {
        this.itemOfferForm.get('parentOffer').reset();
      }

      if (this.itemOfferForm.get('offerType').value !== '') {
        this.itemOfferForm.get('offerType').reset();
        this.preSelectedTypeOffers = [];
      }

      if (value !== '' && this.parentOffers.length > 0) {
        this.preSelectedParentOffers = this.parentOffers.filter(offer => offer.typeDispo === value);
      }

      /* Format Preselection */
      this.formatService.getList({typeItem: value})
        .subscribe(formats => {
          if (formats && formats.format) {
            this.validFormats =  formats.format.map(formatfromDB => {
              return new Format(formatfromDB);
            });
          }
        });

      /* Get capping types and fw capping types from DB */
      this.getAllCappingTypes(Number(value));

      /* Get diffusion mode from DB */
      this.diffusionModeService.getList(value)
        .subscribe(
          diffusionModes => {
            this.diffusionModes = diffusionModes;
          }
        );
    });

    this.itemOfferForm.get('offerType').valueChanges.subscribe(value => {
      this.offerTypeValue = value;

      if (this.offerToEdit && this.offerToEdit.parentOffer
        && this.offerToEdit.parentOffer.offerType === value
        && this.offerToEdit.parentOffer.id) {
        this.itemOfferForm.get('parentOffer').patchValue(this.offerToEdit.parentOffer.id);
      } else {
        this.itemOfferForm.get('parentOffer').reset();
      }

      if (value !== '' && this.preSelectedParentOffers.length > 0) {
          this.preSelectedTypeOffers = this.preSelectedParentOffers.filter(offer => offer.offerType === value);
      }
    });
  }

  limitAndCountCharachtersInTextArea() {
    this.itemOfferForm.get('description').valueChanges.subscribe(value => {
      if ( value && value.length < 4001) {
        this.numberOfCharactersLeft = 4000 - value.length;
      } else if ( value) {
        const newValue = value.substring(0, 4000);
        this.itemOfferForm.get('description').patchValue(newValue);
      }
    });
  }

  addNewTemplate() {
    const newTemplate: ItemOfferTemplate = {
      id: null,
      itemOffer: Number(this.id),
      title: 'Template ' + (this.offerToEdit.templates.length + 1),
      position: this.offerToEdit.templates.length + 1,
      bActif: true,
      bVisuCommerce: true,
      isUsedByCommerce: false,
      budget: null,
      budgetIsEditable: true,
      cpm: null,
      cpmIsEditable: true,
      impressionsNb: null,
      impressionsNbIsEditable: true,
      cappingQuantity: null,
      cappingIdFw: null,
      cappingDuration: null,
      cappingTypeId: null,
      cappingIsEditable: true,
      formats: this.validFormats,
      diffusionModes: this.diffusionModes,
      diffusionRangeEditMode: EditMode.Full,
      diffusionRanges: {
        included: [],
        limited: [],
        excluded: []
      },
      targetingEditMode: EditMode.Full,
      targetings: {
        included: [],
        limited: [],
        excluded: []
      },
      scheduleIsEditable: true,
      schedule: {
        days: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        },
        startTime: {
          hours: '00',
          minutes: '00'
        },
        endTime: {
          hours: this.typeDispoValue === AppConstants.typeDisplay ? '24' : '23',
          minutes: this.typeDispoValue === AppConstants.typeDisplay ? '00' : '59'
        },
        userTimeZone: true,
      }
    };

    this.itemOfferTemplateService.create(newTemplate).subscribe(
      template => {
        if (template instanceof ItemOfferTemplate) {
          this.offerToEdit.templates.unshift(template);
          this.snackBar.open(
            'Le template a été créé.',
            null,
            {duration: 1000, verticalPosition: 'top'}
          );
        } else {
          this.snackBar.open(
            'Une erreur est survenue lors de la création du template.',
            null,
            {duration: 1000, verticalPosition: 'top', panelClass: ['chip-error']}
          );
        }
      },
      () => {
        this.snackBar.open(
          'Le template n\'a pas pu être enregistré.',
          null,
          {duration: 1000, verticalPosition: 'top', panelClass: ['chip-error']}
        );
      }
    );
  }

  deleteTemplate(position: number) {
    this.offerToEdit.templates = this.offerToEdit.templates
      .filter(template => template.position !== position)
      .map((template, index) => {
        template.position = index + 1;
        return template;
      });
  }

  openDialogToReorderTemplates() {
    const simplifiedTemplates = this.offerToEdit.templates.map(template => {
      return {
        itemOffer: template.itemOffer,
        id: template.id,
        title: template.title,
        position: template.position
      };
    });
    const dialogRef = this.dialog.open(TemplateReorderDialogComponent, {
      width: '600px',
      disableClose: true,
      data: { templates: simplifiedTemplates},
    });

    dialogRef.afterClosed().subscribe(reorderedTemplates => {
      if (reorderedTemplates) {
        this.offerToEdit.templates = reorderedTemplates;
      }
    });
  }
}
